

import React, { useEffect, useRef, useState } from "react";
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-video.css";
import "../assets/style/vedio.css";
import { FaCopy } from 'react-icons/fa';
import image1 from "../assets/images/ap-11.jpg"
import "../assets/style/banner.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "../assets/style/swiper.css";
import { MdOutlinePlayCircle } from "react-icons/md";

import { Navigation } from "swiper/modules";
import { MdClose, MdArrowBackIos, MdArrowForwardIos } from "react-icons/md"; 
import "../assets/style/vedio.css";
import "swiper/css/navigation";

const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL

const Portfoliopage = ({ sections = [], cardTitle, cardDescription, cardLogo ,credentials=[] }) => {
  const swiperRef = useRef(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImageTitle, setSelectedImageTitle] = useState(null);
  const [selectedImageDesc, setSelectedImageDesc] = useState(null);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [selectedVideoTitle, setSelectedVideoTitle] = useState(null);
  const [selectedVideoDesc, setSelectedVideoDesc] = useState(null);

  const handleClick = (imageUrl, title, description) => {
    setSelectedImage(`${REACT_APP_BASE_URL}/${imageUrl}`);
    setSelectedImageTitle(title);
    setSelectedImageDesc(description);
  };

  const handleVideoClick = (videoUrl, title, description) => {
    console.log('Description:', description);  
    setSelectedVideo(`${REACT_APP_BASE_URL}/${videoUrl}`);
    setSelectedVideoTitle(title);
    setSelectedVideoDesc(description);
  };

  const handleClosed = () => {

    setSelectedImage(null);
    setSelectedImageTitle(null);
    setSelectedImageDesc(null);
    setSelectedVideo(null);
    setSelectedVideoTitle(null);
    setSelectedVideoDesc(null);

  };

  const visibleCredentials = credentials.filter(cred => cred.is_visible === 1);

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text).then(
      () => console.log('Text copied to clipboard'),
      (err) => console.error('Failed to copy text:', err)
    );
  };
  const getMediaByType = (media, type) => {
    return media
      .filter((item) => item.image.endsWith(type))
      .map((item) => item.image);
  };

  return (
    <>
      <div>
        <section className="portfolio-header">
          <div className="container">
            <div className="row">
              <div className="intro">
                <div className="content-wrapper">
                  <div className="logo-circle col-3">
                    <img src={cardLogo} alt="Logo" />
                  </div>
                  <div className="quote col-8">
                    <h1>{cardTitle}</h1>
                    <p>{cardDescription}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {sections.map((section) => {
          let media = [];
          try {
            media = typeof section.media === "string" ? JSON.parse(section.media) : section.media;
          } catch (e) {
            console.error("Error parsing JSON:", e);
          }

          const imageUrls = getMediaByType(media, ".jpg").concat(getMediaByType(media, ".png"));
          const videoUrls = getMediaByType(media, ".mp4").concat(getMediaByType(media, ".mov"));

          return (
            <React.Fragment key={section.id}>
              {imageUrls.length > 0 && (
                 <section className="imageGallery">
                 <div className="container">
                   <div className="row">
                     <div className="small-portfolio heading text-left">
                       <h4 className="portfolio-heading">{section.title}</h4>
                       {imageUrls.length > 4 && (
                         <div className="navigation-icons">
                           <MdArrowBackIos
                             className="prev-icon"
                             onClick={() => swiperRef.current.swiper.slidePrev()}
                           />
                           <MdArrowForwardIos
                             className="next-icon"
                             onClick={() => swiperRef.current.swiper.slideNext()}
                           />
                         </div>
                       )}
                     </div>
         
                     <div className="img-gallery">
                       <Swiper
                         ref={swiperRef}
                         spaceBetween={10}
                         slidesPerView={4}
                         loop={true}
                         
                         breakpoints={{
                           300: { slidesPerView: 1 },
                           500: { slidesPerView: 1 },
                           600: { slidesPerView: 1 },
                           768: { slidesPerView: 3 },
                           991: { slidesPerView: 4 },
                         }}
                         modules={[Navigation]}
                       >
                         {imageUrls.map((url, index) => (
                           <SwiperSlide key={index}>
                             <div
                               className="box"
                               onClick={() => handleClick(url, section.title, section.description)}
                             >
                               <img src={`${REACT_APP_BASE_URL}/${url}`} alt={`Gallery Image ${index + 1}`} />
                               <div className="box-content">
                                 <h3 className="name">{section.title}</h3>
                               </div>
                             </div>
                           </SwiperSlide>
                         ))}
                       </Swiper>
                     </div>
         
                     <div className="imagegallery-p pt-3">
                       <p>{section.description}</p>
                       <br />
                     </div>
                   </div>
                 </div>
               </section>
              )}

              {videoUrls.length > 0 && (
                <section className="video">
                <div className="container">
                  <div className="row">
                    <div className="small-portfolio heading text-left">
                      <h4 className="portfolio-heading">{section.title}</h4>
                    </div>
                    <div className="header">
                      <div className="gallery-container">
                        {videoUrls.map((url, index) => (
                          <div
                            key={index}
                              className="video-item"
                            onClick={() => handleVideoClick(url, section.title)}
                          >
                          <div className="video-thumbnail">
                    <MdOutlinePlayCircle 
                    className="play-icon" />
                    <div className="overlay-text">Click to Play</div>
                  </div>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="video-p pt-3">
                      <p>{section.description}</p>
                      <br />
                    </div>
                  </div>
                </div>
              </section>
              
              )}

            </React.Fragment>
          );
        })}

        {selectedImage && (
          <div className="full-screen" onClick={handleClosed}>
          <div
            className="full-screen-image-container"
            onClick={(e) => e.stopPropagation()}
          >
            <button
              className="close-button"
              onClick={handleClosed}
            >
              Close
            </button>
            <h4 className="section-title mb-3 mt-3">{selectedImageTitle}</h4>
            <div className="d-flex justify-content-center">
                        <img src={selectedImage} alt="Full Screen" />
                        </div>
            <h3 className="full-screen-title">{selectedImageTitle}</h3>
            <p className="full-screen-desc">{selectedImageDesc}</p>
          </div>
        </div>
        
        )}
{selectedVideo && (
  <div className="full-screen" onClick={handleClosed}>
    <div
      className="full-screen-video-container"
      onClick={(e) => e.stopPropagation()}
    >
      <button
        className="close-button-vedio"
        onClick={handleClosed}
      >
        &times; 
      </button>
      <h4 className="section-title mb-3 mt-3">{selectedVideoTitle}</h4>
      <div className="d-flex justify-content-center">
        <video
          src={selectedVideo}
          controls
          className="full-screen-video"
        />
      </div>
      <div className="video-description mt-3">
        <h5 className="video-title">{selectedVideoTitle}</h5>
        <p className="video-desc">{selectedVideoDesc}</p>
      </div>
    </div>
  </div>
)}





{visibleCredentials.length > 0 && (
        <section className="">
          <div className="container">
            <div className="last-section section-card">
              {visibleCredentials.map((cred) => (
                <div className="cards" key={cred.id}>
                  <h5 className="title">{cred.title}</h5>

                  <a href={cred.url} className="link" target="_blank" rel="noopener noreferrer">
                    {cred.url.length > 20 
                      ? cred.url.substring(0, 20) + '...' 
                      : cred.url}
                  </a>

                  {/* <h5 className="card-description ">
                  <span>UserName: </span>  {cred.username}
                    <FaCopy 
                  className="copy-icon" 
                  style={{ color: "#fd6a01" }} 
                  onClick={() => handleCopy(cred.username)} 
                />                  
                
                
                </h5> */}


<div className="card-description-row">
    <span className="card-description-label">Username:</span>
    <div className="card-description d-flex justify-content-between">{cred.username}  <FaCopy 
      className="copy-icon" 
      style={{ color: "#fd6a01" }} 
      onClick={() => handleCopy(cred.username)} 
    /></div>
   
  </div>
  <div className="card-description-row">
    <span className="card-description-label">Password:</span>
    <div className="card-description d-flex justify-content-between">{cred.password}  <FaCopy 
      className="copy-icon" 
      style={{ color: "#fd6a01" }} 
      onClick={() => handleCopy(cred.password)} 
    /></div>
   
  </div>

                
                </div>
              ))}
            </div>
          </div>
        </section>
      )}




      </div>
    </>
  );
};

export default Portfoliopage;