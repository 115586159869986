



import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";

import InnerHeader from "../components/InnerHeader";

import { MdClose } from 'react-icons/md';
import "../assets/style/newportfolio.css";

import "../assets/style/portfolionew.css";
import Portfoliopage from "./Portfoliopage";
import { fetchPortfolioData } from "./Api";
const colors = ['#1d4071'];

const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL

const PortfolioNew = () => {
  const [selectedCard, setSelectedCard] = useState(null);
  const [cardPosition, setCardPosition] = useState(null);
  const [portfolioData, setPortfolioData] = useState([]);
  const [scrollY, setScrollY] = useState(0);
  const [formData, setFormData] = useState(new FormData());
  const buttonRefs = useRef([]);

  useEffect(() => {
    const getPortfolioData = async () => {
      try {
        const data = await fetchPortfolioData();
       
        const decodedData = data.map(
          card => ({
          ...card,          gallery: JSON.parse(card.gallery), sections: card.sections.map(section => ({
            ...section,
            media: JSON.parse(section.media),
          })),
           credentials: card.credentials.map((credential) => ({
            ...credential,
          })),
        }));
        setPortfolioData(decodedData);
      } catch (error) {
        console.error('Failed to fetch portfolio data:', error);
      }
    };

    getPortfolioData();
  }, []);

  const openBanner = (card, index) => {
    const buttonElement = buttonRefs.current[index];
    if (buttonElement) {
      const rect = buttonElement.getBoundingClientRect();
      setCardPosition(rect);
      setSelectedCard(card);
      document.body.style.overflow = 'hidden';
    }
  };

  const closeBanner = () => {
    setSelectedCard(null);
    setCardPosition(null);
    document.body.style.overflow = '';
  };

  const handleScroll = () => {
    setScrollY(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  

  return (
    <>
      <InnerHeader />
      <div className="inner-header" id="portfolio-main">
        {/* Your InnerHeader content */}
      </div>

      <div className="container" style={{
        padding:"20px",
      }}>

<div className="row">
  <div className="col-lg-12">
    {portfolioData.map((card, i) => (
      <div
        className="featuredPropBox"
        key={card.id}
        style={{ backgroundImage: `url(${REACT_APP_BASE_URL}${card.logo}` }} // dynamic background image
      >
        <ul>
          <li>
            <a href="#">
              <div className="fptext">
                <p>{card.description}</p>
              </div>
            </a>
          </li>
        </ul>
      </div>
    ))}
  </div>
</div>































      {/* <div className="grid-wrapper">
          {portfolioData.map((card, i) => (
            <motion.div
              className={`card ${['', 'wide', 'tall', 'big'][i % 4]}`}
              id={`card-${i}`}
              key={card.id} // Changed key to card.id for uniqueness
              ref={(el) => buttonRefs.current[i] = el}
            >
              <div className="header" style={{ background: 'none' }}>
              <img src={`${REACT_APP_BASE_URL}${card.logo}`} alt={card.title} />
              </div>
              <div className="footer" style={{ '--footer-bg-color': colors[i % colors.length] }}>
                <h1>{card.title}</h1>
                <p>{card.description}</p>
                <button
                  style={{
                    margin: "0px",
                    padding: "5px ",
                    fontSize:"14px"
                  }}
                  className="contact_btn"
                  onClick={() => openBanner(card, i)}
                >
                  View more
                </button>
              </div>
            </motion.div>
          ))}
        </div>  */}

        <AnimatePresence>
          {selectedCard && cardPosition && (
            <motion.div
              className="card-modal-overlay"
              onClick={closeBanner}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 1 }}
            >
              <motion.div
                className="card-modal"
                onClick={(e) => e.stopPropagation()}
                initial={{
                  scale: 0,
                  x: cardPosition.x + cardPosition.width / 2,
                  y: cardPosition.y + cardPosition.height / 2,
                  rotate: 180,
                  skewX: 45,
                }}
                animate={{
                  scale: 1,
                  x: 0,
                  y: 0,
                  rotate: 0,
                  skewX: 0,
                }}
                exit={{
                  scale: 0,
                  x: cardPosition.x + cardPosition.width / 2,
                  y: cardPosition.y + cardPosition.height / 2,
                  rotate: 180,
                  skewX: 45,
                }}
                transition={{
                  type: 'spring',
                  stiffness: 300,
                  damping: 30,
                  duration: 2,
                }}
              >
                <div className="close-icon" onClick={closeBanner}>
                  <MdClose />
                </div>
                <div className="modal-content-1">
                  <Portfoliopage 
           
                    sections={selectedCard.sections} 
                    cardTitle={selectedCard.title}
                    cardDescription={selectedCard.description}
                    cardLogo={REACT_APP_BASE_URL + selectedCard.logo}
               
                    credentials={selectedCard.credentials}

                  
                  />
                </div>
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </>
  );
};

export default PortfolioNew;