// import React, { useState } from "react";
// import InnerHeader from "./InnerHeader";
// import "../assets/style/BlogDetails.css";
// import {
//   FaFacebookSquare,
//   FaInstagramSquare,
//   FaTwitterSquare,
//   FaLinkedin,
//   FaShareAlt,
//   FaUserCircle,
//   FaSortDown,
// } from "react-icons/fa";
// import CommentLogin from "./CommentLogin";
// import Modal from "react-modal";
// import { FaGoogle } from "react-icons/fa";
// Modal.setAppElement("#root");






// const BlogDetail = () => {
//   const [dropdownOpen, setDropdownOpen] = useState(false);
//   const [sortOption, setSortOption] = useState("Sort by oldest");
//   const [visibleReplies, setVisibleReplies] = useState({});
//   const [comments, setComments] = useState([]); // Initialize with an empty array
//   const [newComment, setNewComment] = useState("");
//   const [newReply, setNewReply] = useState("");
//   const [thumbsUpCounts, setThumbsUpCounts] = useState({});
//   const [dropdownOpenDelete, setDropdownOpenDelete] = useState(null);
//   const [modalIsOpen, setModalIsOpen] = useState(false);

//   const openModal = () => setModalIsOpen(true);
//   const closeModal = () => setModalIsOpen(false);

//   const handleReplyClick = (commentId) => {
//     setVisibleReplies((prev) => ({
//       ...prev,
//       [commentId]: !prev[commentId],
//     }));
//   };
//   const handleThumbsUpClick = (commentId) => {
//     setThumbsUpCounts((prev) => ({
//       ...prev,
//       [commentId]: (prev[commentId] || 0) + 1,
//     }));
//   };
//   const toggleDropdown = (replyId) => {
//     console.log(`Toggling dropdown for reply ${replyId}`);
//     setDropdownOpenDelete((prev) => (prev === replyId ? null : replyId));
//   };

//   const handleAddAction = (replyId) => {
//     console.log(`Add action for reply ${replyId}`);
//   };

//   const handleDeleteAction = (replyId) => {
//     console.log(`Delete action for reply ${replyId}`);
//   };

//   const handleOptionClick = (option) => {
//     setSortOption(option);
//     setDropdownOpen(false);
//   };

//   const handleCommentChange = (e) => {
//     setNewComment(e.target.value);
//   };

//   const handleReplyChange = (e) => {
//     setNewReply(e.target.value);
//   };

//   const handleAddComment = () => {
//     if (newComment.trim()) {
//       setComments((prev) => [
//         ...prev,
//         {
//           id: Date.now(),
//           author: "newUser",
//           date: new Date().toLocaleDateString(),
//           text: newComment,
//           replies: [],
//         },
//       ]);
//       setNewComment("");
//     }
//   };

//   const handleAddReply = (commentId) => {
//     if (newReply.trim()) {
//       setComments((prev) =>
//         prev.map((comment) =>
//           comment.id === commentId
//             ? {
//                 ...comment,
//                 replies: [
//                   ...comment.replies,
//                   {
//                     id: Date.now(),
//                     author: "newUser",
//                     date: new Date().toLocaleDateString(),
//                     text: newReply,
//                   },
//                 ],
//               }
//             : comment
//         )
//       );
//       setNewReply("");
//       setVisibleReplies((prev) => ({
//         ...prev,
//         [commentId]: false,
//       }));
//     }
//   };

//   return (
//     <>
//       <InnerHeader />
//       <div className="blog-details">
//         <main>
//           <section className="section1">
//             <div className="blog-details-sectin-1-title">
//               <h1>Programming</h1>
//               <p className="mb-0">06-Sep-2024</p>
//             </div>
//             <a href="" className="unknown-author-link">
//               - AI Expert
//             </a>
//           </section>

//           <section className="section2">
//             <div className="section-img">
//               <img src="https://wallpapercave.com/wp/wp6350578.jpg" alt="" />
//             </div>
//           </section>

//           <section className="section3">
//             <div className="blog-details-section-3-share">
//               <div className="blog-details-section-3-share-post">
//                 <div className="blog-details-section-3-share-post-title">
//                   <h3>Share Post</h3>
//                   <div className="blog-details-section-3-share-post-icons">
//                     <div className="">
//                       <FaFacebookSquare />
//                       <FaLinkedin />
//                       <FaInstagramSquare />
//                       <FaTwitterSquare />
//                     </div>
//                   </div>
//                 </div>
//                 <div className="blog-line"></div>
//                 <div className="blog-details-section-3-list">
//                   <p>
//                     Artificial Intelligence (AI) is rapidly changing the way
//                     businesses manage their IT infrastructure...
//                   </p>
//                   <p>
//                     In today’s fast-paced digital age, the role of Information
//                     Technology (IT) has never been more crucial...
//                   </p>
//                   <p>
//                     Artificial Intelligence frees up IT professionals to focus
//                     on innovation.
//                   </p>
//                 </div>

//                 <div className="blog-line"></div>
//                 <div className="blog-details-section-3-share-post-title">
//                   <h3>Recent Post</h3>
//                 </div>
//                 <div className="blog-details-section-3-recent">
//                   <div className="blog-details-section-3-recent-post-data ">
//                     <div className="recent-post col-lg-3 col-md-6 col-sm-12 p-2">
//                       <img
//                         src="https://i.pinimg.com/originals/ba/0e/b8/ba0eb82dbe74fb21925083c2ea7475b4.jpg"
//                         className="recent-image mb-3"
//                         alt=""
//                       />
//                       <h5>AI Expert</h5>
//                       <div className="">
//                         <p>
//                           AI has transformed how companies manage IT
//                           infrastructure, offering predictive maintenance and
//                           automation
//                         </p>
//                       </div>
//                       <button className="imported-btn mt-2" type="">
//                         Lorem
//                       </button>
//                     </div>
//                     <div className="recent-post col-lg-3 col-md-6 col-sm-12 p-2">
//                       <img
//                         src="https://wallpapercave.com/wp/wp5167010.jpg"
//                         className="recent-image mb-3"
//                         alt=""
//                       />
//                       <h5>Programming</h5>
//                       <div className="">
//                         <p>
//                           AI has transformed how companies manage IT
//                           infrastructure, offering predictive maintenance and
//                           automation
//                         </p>
//                       </div>

//                       <button className="imported-btn mt-2" type="">
//                         Lorem
//                       </button>
//                     </div>
//                   </div>
//                 </div>

//                 <div className="blog-line mt-5"></div>

//                 <div className="comments-section">
//                   <h3 className="comments-title mb-3">Comments</h3>

//                   <div className="comments-header">
//                     <h5 className="comments-count">
//                       {comments.length} comments
//                     </h5>
//                   </div>

//                   <div className="blog-line"></div>

//                   <div className="comment-wrapper">
//                     <div className="comment-header">
//                       <div className="coment-header-section-first">
//                         <h5 className="recommendation">
//                           <span className="recommendation-number">💗</span>{" "}
//                           Recommendation
//                         </h5>
//                       </div>
//                       <div className="sort-options relative">
//                         Sort by
//                         <a
//                           onClick={toggleDropdown}
//                           className="flex items-center gap-1"
//                         >
//                           <FaSortDown className="w-4 h-4" />
//                         </a>
//                         {dropdownOpen && (
//                           <div className="absolute right-0 mt-2 w-48 bg-white shadow-md border rounded-lg z-10">
//                             <ul className="py-2">
//                               <li
//                                 className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
//                                 onClick={() =>
//                                   handleOptionClick("Sort by oldest")
//                                 }
//                               >
//                                 Oldest
//                               </li>
//                               <li
//                                 className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
//                                 onClick={() =>
//                                   handleOptionClick("Sort by lowest")
//                                 }
//                               >
//                                 Lowest
//                               </li>
//                             </ul>
//                           </div>
//                         )}
//                       </div>
//                     </div>

//                     <div className="add-comment">
//                       <textarea
//                         type="text"
//                         placeholder="Add a comment..."
//                         className="comment-input"
//                         value={newComment}
//                         onChange={handleCommentChange}
//                       />
//                       <div className="comment-login">
//                         <button
//                           className="imported-btn mt-2"
//                           type="button"
//                           onClick={handleAddComment}
//                         >
//                           Add Comment
//                         </button>

//                         <button
//                           className="imported-btn mt-2"
//                           onClick={openModal}
//                         >
//                           Login
//                         </button>
//                       </div>
//                     </div>
//                         <div>
//                           <Modal
//                             isOpen={modalIsOpen}
//                             onRequestClose={closeModal}
//                             contentLabel="Sign Up Modal"
//                             className="modal"
//                             overlayClassName="overlay"
//                           >
//                             <h2>Create an account to write a response</h2>
//                             <button className="google-signup-btn">
//                               <FaGoogle /> Sign Up with Google
//                             </button>
//                           </Modal>
//                         </div>

//                     <div className="comment-wrapper">
//                       {comments.map((comment) => (
//                         <div key={comment.id} className="comment">
//                           <FaUserCircle className="comment-avatar" />
//                           <div className="comment-details">
//                             <h5 className="comment-author">{comment.author}</h5>
//                             <p className="comment-date">{comment.date}</p>
//                             <p className="comment-text">{comment.text}</p>
//                             <div className="thumb">
//                               <div className="like">
//                                 <span className="pt-2">
//                                   {thumbsUpCounts[comment.id] || 0}
//                                 </span>
//                                 <button
//                                   className="thumbs-up-btn"
//                                   onClick={() =>
//                                     handleThumbsUpClick(comment.id)
//                                   }
//                                 >
//                                   👍
//                                 </button>
//                               </div>
//                               <h5
//                                 className="pt-2"
//                                 style={{ fontSize: "12px", cursor: "pointer" }}
//                                 onClick={() => handleReplyClick(comment.id)}
//                               >
//                                 Reply
//                               </h5>
//                             </div>
//                             {visibleReplies[comment.id] && (
//                               <div className="reply-box">
//                                 <input
//                                   type="text"
//                                   placeholder="What's your thought?"
//                                   className="reply-input"
//                                   value={newReply}
//                                   onChange={handleReplyChange}
//                                 />
//                                 <div className="d-flex gap-3">
//                                   <button
//                                     className="imported-btn mt-2"
//                                     type="button"
//                                     onClick={() => handleAddReply(comment.id)}
//                                   >
//                                     Send
//                                   </button>
//                                   <button className="imported-btn mt-2">
//                                     Cancle
//                                   </button>
//                                 </div>
//                               </div>
//                             )}
//                             {comment.replies.length > 0 && (
//                               <div className="replies">
//                                 {comment.replies.map((reply) => (
//                                   <div key={reply.id} className="reply">
//                                     <div className="reply-image">
//                                       <FaUserCircle
//                                         className="reply-avatar"
//                                         style={{ fontSize: "30px" }}
//                                       />
//                                       <div className="reply-details">
//                                         <h5 className="reply-author">
//                                           {reply.author}
//                                         </h5>
//                                         <p className="reply-date">
//                                           {reply.date}
//                                         </p>
//                                         <p className="reply-text">
//                                           {reply.text}
//                                         </p>
//                                       </div>
//                                     </div>
//                                     <div className="reply-actions">
//                                       <span
//                                         className="more-options"
//                                         onClick={() => {
//                                           console.log("Ellipsis clicked");
//                                           toggleDropdown(reply.id);
//                                         }}
//                                         style={{ cursor: "pointer" }}
//                                       >
//                                         ...
//                                       </span>
//                                       {dropdownOpenDelete === reply.id && (
//                                         <div className="dropdown-menu-delete p-2">
//                                           <button
//                                             className="dropdown-item-delete"
//                                             onClick={() =>
//                                               handleDeleteAction(reply.id)
//                                             }
//                                           >
//                                             Delete
//                                           </button>
//                                         </div>
//                                       )}
//                                     </div>
//                                   </div>
//                                 ))}
//                               </div>
//                             )}
//                           </div>
//                         </div>
//                       ))}
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </section>
//         </main>
//       </div>
//     </>
//   );
// };

// export default BlogDetail;



import React, { useEffect, useState } from "react";
import InnerHeader from "./InnerHeader";
import "../assets/style/BlogDetails.css";
import {
  FaFacebookSquare,
  FaInstagramSquare,
  FaTwitterSquare,
  FaLinkedin,
  FaUserCircle,
  FaSortDown,
  FaTimes,
} from "react-icons/fa";
import Modal from "react-modal";
import { FaGoogle } from "react-icons/fa";
import { auth, provider } from "./google";
import { signInWithPopup ,signOut } from "firebase/auth";

const BlogDetail = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [sortOption, setSortOption] = useState("Sort by oldest");
  const [visibleReplies, setVisibleReplies] = useState({});
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState("");
  const [newReply, setNewReply] = useState("");
  const [thumbsUpCounts, setThumbsUpCounts] = useState({});
  const [dropdownOpenDelete, setDropdownOpenDelete] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [userEmail, setUserEmail] = useState('');
  const [userName, setUserName] = useState('');
  const [userPhotoURL, setUserPhotoURL] = useState('');


  const openModal = () => setModalIsOpen(true);
  const closeModal = () => setModalIsOpen(false);
  const handleSignIn = async () => {
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      const email = user.email;
      const username = user.displayName;
      const photoURL = user.photoURL; // Get the user's profile picture URL
  
      setUserEmail(email);
      setUserName(username || "");
      localStorage.setItem("email", email);
      localStorage.setItem("username", username || "");
      localStorage.setItem("photoURL", photoURL || ""); // Save profile picture URL
  
      closeModal(); // Close the modal after successful login
    } catch (error) {
      console.error("Error during sign-in:", error);
    }
  };
  const handleSignOut = async () => {
    try {
      await signOut(auth);
      setUserEmail('');
      setUserName('');
      setUserPhotoURL('');
      localStorage.removeItem("email");
      localStorage.removeItem("username");
      localStorage.removeItem("photoURL");
    } catch (error) {
      console.error("Error during sign-out:", error);
    }
  };
  useEffect(() => {
    const storedEmail = localStorage.getItem('email');
    const storedUsername = localStorage.getItem('username');
    const storedPhotoURL = localStorage.getItem('photoURL');
    
    setUserEmail(storedEmail);
    setUserName(storedUsername);
    setUserPhotoURL(storedPhotoURL || ""); // Default to empty string if no photoURL
  }, []);
  

  const handleReplyClick = (commentId) => {
    setVisibleReplies((prev) => ({
      ...prev,
      [commentId]: !prev[commentId],
    }));
  };

  const handleThumbsUpClick = (commentId) => {
    setThumbsUpCounts((prev) => ({
      ...prev,
      [commentId]: (prev[commentId] || 0) + 1,
    }));
  };

  const toggleDropdown = (replyId) => {
    setDropdownOpenDelete((prev) => (prev === replyId ? null : replyId));
  };

  const handleAddAction = (replyId) => {
    console.log(`Add action for reply ${replyId}`);
  };

  const handleDeleteAction = (replyId) => {
    console.log(`Delete action for reply ${replyId}`);
  };

  const handleOptionClick = (option) => {
    setSortOption(option);
    setDropdownOpen(false);
  };

  const handleCommentChange = (e) => {
    setNewComment(e.target.value);
  };

  const handleReplyChange = (e) => {
    setNewReply(e.target.value);
  };
  const handleAddComment = () => {
    if (!userEmail) {
      openModal();
      return;
    }
  
    if (newComment.trim()) {
      setComments((prev) => [
        ...prev,
        {
          id: Date.now(),
          author: userName, // Use user's email here
          date: new Date().toLocaleDateString(),
          text: newComment,
          replies: [],
        },
      ]);
      setNewComment("");
    }
  };
  

  const handleAddReply = (commentId) => {
    if (newReply.trim()) {
      setComments((prev) =>
        prev.map((comment) =>
          comment.id === commentId
            ? {
                ...comment,
                replies: [
                  ...comment.replies,
                  {
                    id: Date.now(),
                    author: userName,
                    date: new Date().toLocaleDateString(),
                    text: newReply,
                  },
                ],
              }
            : comment
        )
      );
      setNewReply("");
      setVisibleReplies((prev) => ({
        ...prev,
        [commentId]: false,
      }));
    }
  };

  return (
    <>
      <InnerHeader />

      <div className="blog-details">
         <main>
           <section className="section1">
             <div className="blog-details-sectin-1-title">
               <h1>Programming</h1>
               <p className="mb-0">06-Sep-2024</p>
             </div>
             <a href="" className="unknown-author-link">
               - AI Expert
             </a>
           </section>

           <section className="section2">
             <div className="section-img">
               <img src="https://wallpapercave.com/wp/wp6350578.jpg" alt="" />
             </div>
           </section>

                 <section className="section3">
             <div className="blog-details-section-3-share">
               <div className="blog-details-section-3-share-post">
                 <div className="blog-details-section-3-share-post-title">
                   <h3>Share Post</h3>
                   <div className="blog-details-section-3-share-post-icons">
                     <div className="">
                       <FaFacebookSquare />
                       <FaLinkedin />
                       <FaInstagramSquare />
                       <FaTwitterSquare />
                     </div>
                   </div>
                 </div>
                 <div className="blog-line"></div>
                 <div className="blog-details-section-3-list">
                   <p>
                     Artificial Intelligence (AI) is rapidly changing the way
                     businesses manage their IT infrastructure...
                   </p>
                   <p>
                     In today’s fast-paced digital age, the role of Information
                     Technology (IT) has never been more crucial...
                   </p>
                   <p>
                     Artificial Intelligence frees up IT professionals to focus
                     on innovation.
                   </p>
                 </div>

                 <div className="blog-line"></div>
                 <div className="blog-details-section-3-share-post-title">
                   <h3>Recent Post</h3>
                 </div>
                 <div className="blog-details-section-3-recent">
                   <div className="blog-details-section-3-recent-post-data ">
                     <div className="recent-post col-lg-3 col-md-6 col-sm-12 p-2">
                       <img
                        src="https://i.pinimg.com/originals/ba/0e/b8/ba0eb82dbe74fb21925083c2ea7475b4.jpg"
                        className="recent-image mb-3"
                        alt=""
                      />
                      <h5>AI Expert</h5>
                      <div className="">
                        <p>
                          AI has transformed how companies manage IT
                          infrastructure, offering predictive maintenance and
                          automation
                        </p>
                      </div>
                      <button className="imported-btn mt-2" type="">
                        Lorem
                      </button>
                    </div>
                    <div className="recent-post col-lg-3 col-md-6 col-sm-12 p-2">
                      <img
                        src="https://wallpapercave.com/wp/wp5167010.jpg"
                        className="recent-image mb-3"
                        alt=""
                      />
                      <h5>Programming</h5>
                      <div className="">
                        <p>
                          AI has transformed how companies manage IT
                          infrastructure, offering predictive maintenance and
                          automation
                        </p>
                      </div>

                      <button className="imported-btn mt-2" type="">
                        Lorem
                      </button>
                    </div>
                  </div>
                </div>

                <div className="blog-line mt-5"></div>
            <div className="comments-section">
              <h3 className="comments-title mb-3">Comments</h3>

              <div className="comments-header">
                <h5 className="comments-count">
                  {comments.length} comments
                </h5>
              </div>

              <div className="blog-line"></div>

              <div className="comment-wrapper">
                <div className="comment-header">
                  <div className="coment-header-section-first">
                    <h5 className="recommendation">
                      <span className="recommendation-number">💗</span>{" "}
                      Recommendation
                    </h5>
                  </div>
                  <div className="sort-options relative">
                        Sort by
                        <a
                          onClick={toggleDropdown}
                          className="flex items-center gap-1"
                        >
                          <FaSortDown className="w-4 h-4" />
                        </a>
                        {dropdownOpen && (
                          <div className="absolute right-0 mt-2 w-48 bg-white shadow-md border rounded-lg z-10">
                            <ul className="py-2">
                              <li
                                className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                                onClick={() =>
                                  handleOptionClick("Sort by oldest")
                                }
                              >
                                Oldest
                              </li>
                              <li
                                className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                                onClick={() =>
                                  handleOptionClick("Sort by lowest")
                                }
                              >
                                Lowest
                              </li>
                            </ul>
                          </div>
                        )}
                      </div>
                </div>

         
      <div className="add-comment">
        <textarea
          type="text"
          placeholder="Add a comment..."
          className="comment-input"
          value={newComment}
          onChange={handleCommentChange}
        />
        <div className="comment-login">
        <button
              className="imported-btn mt-2"
              type="button"
              onClick={handleAddComment}
            >
              Add Comment
            </button>
          {userEmail ? (
            <button
              className="imported-btn mt-2"
              type="button"
              onClick={handleSignOut}
            >
              Logout
            </button>
          ) : (
            <button
              className="imported-btn mt-2"
              type="button"
              onClick={handleSignIn}
            >
              Login
            </button>
          )}
        </div>
      </div>
   
                <div className="comment-wrapper">
                  {comments.map((comment) => (
                    <div key={comment.id} className="comment">
                        
    {userPhotoURL ? (
      <img src={userPhotoURL} alt="User Avatar" className="comment-avatar" />
    ) : (
      <FaUserCircle className="comment-avatar" /> 
    )}
                      <div className="comment-details">
                        <h5 className="comment-author">{comment.author}</h5>
                        <p className="comment-date">{comment.date}</p>
                        <p className="comment-text">{comment.text}</p>
                        <div className="thumb">
                          <div className="like">
                            <span className="pt-2">
                              {thumbsUpCounts[comment.id] || 0}
                            </span>
                            <button
                              className="thumbs-up-btn"
                              onClick={() =>
                                handleThumbsUpClick(comment.id)
                              }
                            >
                              👍
                            </button>
                          </div>
                          <h5
                            className="pt-2"
                            style={{ fontSize: "12px", cursor: "pointer" }}
                            onClick={() => handleReplyClick(comment.id)}
                          >
                            Reply
                          </h5>
                        </div>
                        {visibleReplies[comment.id] && (
                          <div className="reply-box">
                            <input
                              type="text"
                              placeholder="What's your thought?"
                              className="reply-input"
                              value={newReply}
                              onChange={handleReplyChange}
                            />
                            <div className="d-flex gap-3">
                              <button
                                className="imported-btn mt-2"
                                type="button"
                                onClick={() => handleAddReply(comment.id)}
                              >
                                Send
                              </button>
                              <button className="imported-btn mt-2">
                                Cancel
                              </button>
                            </div>
                          </div>
                        )}
                        {comment.replies.length > 0 && (
                          <div className="replies">
                            {comment.replies.map((reply) => (
                              <div key={reply.id} className="reply">
                                <div className="reply-image d-flex">
                                {userPhotoURL ? (
      <img src={userPhotoURL} alt="User Avatar"   className="comment-avatar" style={{width:"35px",
        height:"35px"}}/>
    ) : (
      <FaUserCircle className="comment-avatar"  style={{width:"35px",
        height:"35px"
      }}/> 
    )}
                                  <div className="reply-details">
                                    <h5 className="reply-author">
                                      {reply.author}
                                    </h5>
                                    <p className="reply-date">
                                      {reply.date}
                                    </p>
                                    <p className="reply-text">
                                      {reply.text}
                                    </p>
                                  </div>
                                </div>
                                <div className="reply-actions">
                                  <span
                                    className="more-options"
                                    onClick={() => {
                                      toggleDropdown(reply.id);
                                    }}
                                    style={{ cursor: "pointer" }}
                                  >
                                    ...
                                  </span>
                                  {dropdownOpenDelete === reply.id && (
                                    <div className="dropdown-menu">
                                      <button
                                        className="dropdown-item"
                                        onClick={() => handleAddAction(reply.id)}
                                      >
                                        Add
                                      </button>
                                      <button
                                        className="dropdown-item"
                                        onClick={() => handleDeleteAction(reply.id)}
                                      >
                                        Delete
                                      </button>
                                    </div>
                                  )}
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              </div>
            </div>
            </div>
          </section>
        </main>
       
      </div>
      {modalIsOpen && (
  <div className="modal-overlay">
    <div className="box-modal-google">
      <FaTimes className="close-icon-google" onClick={closeModal}/> 
<div className="google">
      <h2 className="mt-5">Please login to write Comment</h2>
      <button className="google-signup-btn mt-5" onClick={handleSignIn}>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" class="l"><g id="google"><g id="google-vector" fill-rule="evenodd" clip-rule="evenodd"><path id="Shape" fill="#4285F4" d="M20.64 12.205q-.002-.957-.164-1.84H12v3.48h4.844a4.14 4.14 0 0 1-1.796 2.717v2.258h2.908c1.702-1.567 2.684-3.874 2.684-6.615"></path><path id="Shape_2" fill="#34A853" d="M12 21c2.43 0 4.468-.806 5.957-2.18L15.05 16.56c-.806.54-1.837.86-3.048.86-2.344 0-4.328-1.584-5.036-3.711H3.958v2.332A9 9 0 0 0 12.001 21"></path><path id="Shape_3" fill="#FBBC05" d="M6.964 13.712a5.4 5.4 0 0 1-.282-1.71c0-.593.102-1.17.282-1.71V7.96H3.957A9 9 0 0 0 3 12.002c0 1.452.348 2.827.957 4.042z"></path><path id="Shape_4" fill="#EA4335" d="M12 6.58c1.322 0 2.508.455 3.441 1.346l2.582-2.58C16.463 3.892 14.427 3 12 3a9 9 0 0 0-8.043 4.958l3.007 2.332c.708-2.127 2.692-3.71 5.036-3.71"></path></g></g></svg>
       Sign Up with Google
      </button>
      </div>
    </div>
  </div>
)} 
 
    
    </>
  );
};

export default BlogDetail;
