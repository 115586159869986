import React from "react";
import InnerHeader from "./InnerHeader";
import "../assets/style/Blog.css";
import { Link } from "react-router-dom";
const articles = [
  {
    imgSrc: "https://wallpapercave.com/wp/wp6350578.jpg", 
    imgAlt: "Remote Work",
    tabs: ["Technology", "Work"],
    title: "The Future of Remote Work in the IT Industry",
    date: "2023-09-01",
    author: "Jane Doe",
    content:
      "The IT industry has seen a massive shift towards remote work. This article explores the benefits, challenges, and future trends of remote work in the tech sector.",
  },
  {
    imgSrc: "https://i.pinimg.com/originals/ba/0e/b8/ba0eb82dbe74fb21925083c2ea7475b4.jpg",
    imgAlt: "Cybersecurity",
    tabs: ["Security", "Technology"],
    title: "Top Cybersecurity Trends to Watch in 2024",
    date: "2023-08-28",
    author: "John Smith",
    content:
      "With cyber threats evolving rapidly, staying ahead of cybersecurity trends is crucial. Learn about the latest threats and how to protect your business.",
  },
  {
    imgSrc: "https://wallpapercave.com/wp/wp5167010.jpg", 
    imgAlt: "AI in IT",
    tabs: ["AI", "Innovation"],
    title: "How AI is Revolutionizing IT Infrastructure",
    date: "2023-07-15",
    author: "AI Expert",
    content:
      "AI has transformed how companies manage IT infrastructure, offering predictive maintenance and automation. Discover how AI is reshaping the IT landscape.",
  },
  {
    imgSrc: "https://wallpapercave.com/wp/wp7471881.jpg", 
    imgAlt: "Programming",
    tabs: ["Programming", "Development"],
    title: "10 Programming Languages Every Developer Should Know",
    date: "2023-07-30",
    author: "Tech Guru",
    content:
      "Whether you're a seasoned developer or just starting, these 10 programming languages are essential for staying relevant in today's tech-driven world.",
  },
  {
    imgSrc: "https://wallpapercave.com/wp/wp5167010.jpg",
    imgAlt: "Cloud Computing",
    tabs: ["Cloud", "Technology"],
    title: "The Role of Cloud Computing in Digital Transformation",
    date: "2023-06-10",
    author: "Cloud Specialist",
    content:
      "Cloud computing is a key driver of digital transformation across industries. Explore the different ways cloud solutions are enabling businesses to innovate.",
  },
  {
    imgSrc: "https://wallpapercave.com/wp/wp6350578.jpg", 
    imgAlt: "Data Science",
    tabs: ["Data Science", "AI"],
    title: "Data Science for Business: Unlocking Insights from Big Data",
    date: "2023-05-12",
    author: "Data Expert",
    content:
      "Data science is helping companies unlock actionable insights from their data. Learn how big data analysis is driving smarter business decisions.",
  },
];

const newPosts = [
  {
    imgSrc:
      "https://netology-code.github.io/html-2-diploma/sources/images/2.jpg",
    imgAlt: "Cloud Infrastructure",
    date: "2023-09-01",
    title: "How Cloud Infrastructure is Redefining IT Solutions",
  },
  {
    imgSrc:
      "https://netology-code.github.io/html-2-diploma/sources/images/151.jpg",
    imgAlt: "Artificial Intelligence",
    date: "2023-08-25",
    title: "The Role of AI in Automating IT Processes",
  },
  {
    imgSrc:
      "https://netology-code.github.io/html-2-diploma/sources/images/45.jpg",
    imgAlt: "Blockchain Technology",
    date: "2023-08-15",
    title: "Exploring Blockchain's Impact on Data Security",
  },
  {
    imgSrc:
      "https://netology-code.github.io/html-2-diploma/sources/images/501.jpg",
    imgAlt: "5G Network",
    date: "2023-08-05",
    title: "5G: The Next Frontier in IT Networking",
  },
  {
    imgSrc:
      "https://netology-code.github.io/html-2-diploma/sources/images/7.jpg",
    imgAlt: "Software Development",
    date: "2023-07-30",
    title: "Best Practices in Agile Software Development",
  },
];
const tags = [
  "AI",
  "blockchain",
  "cybersecurity",
  "cloud computing",
  "programming",
  "big data",
  "technology",
  "IT trends",
];

const categories = [
  { name: "Technology", count: 18 },
  { name: "Programming", count: 10 },
  { name: "cloud", count: 10 },
  { name: "AI", count: 10 },
]
const Blog = () => (
  <>
    <InnerHeader />

    <section className="blog-banner-section d-flex align-items-center">
      <div className="blog-overlay"></div>
      <div className="container text-center banner-content">
        <h1 className="blog-banner-title"> Blogs</h1>
      </div>
    </section>

    <div className="container content-wrap">
      <main className="main-posts-list ">
        <div className="col-lg-8 col-md-12 col-sm-12 mb-4">
          {articles.map((article, index) => (
            <div className="" key={index}>
              <article className="article d-flex">
                <div className="article-img-column">
                  <Link to="/blogdetail">
                    <img
                      className="article-img img-fluid"
                      src={article.imgSrc}
                      alt={article.imgAlt}
                    />
                  </Link>
                </div>
                <div className="article-text-column">
                  <ul className="tab-list list-unstyled d-flex flex-wrap">
                    {article.tabs.map((tab, index) => (
                      <li className="tab-item me-3" key={index}>
                        <a className="tab-link" href="#0">
                          {tab}
                        </a>
                      </li>
                    ))}
                  </ul>
                  <h2 className="article-title">
                    <a className="article-title-link" href="#0">
                      {article.title}
                    </a>
                  </h2>
                  <time className="article-datetime" dateTime={article.date}>
                    {article.date}
                  </time>
                  <cite className="article-author">{article.author}</cite>
                  <div className="article-content">
                    <p>{article.content}</p>
                  </div>
                </div>
              </article>
            </div>
          ))}
        </div>
        <aside className="sidebar col-lg-4 col-md-8 col-sm-12">
        <div className="search-box">
  <input type="search" placeholder="Search Blog" />
</div>

          <section className="new-posts mb-4">
            <h2 className="sidebar-title">Recent Blog</h2>
            <div className="new-posts-list">
              {newPosts.map((post, index) => (
                <article className="new-posts-article d-flex mb-3" key={index}>
                  <div className="post-img-column">
                    <img
                      className="new-posts-image img-fluid"
                      src={post.imgSrc}
                      alt={post.imgAlt}
                    />
                  </div>
                  <div className="post-text-column ms-3">
                    <time className="new-posts-datetime" dateTime={post.date}>
                      {post.date}
                    </time>
                    <h5 className="new-posts-title">
                      <a className="new-posts-link" href="#0">
                        {post.title}
                      </a>
                    </h5>
                  </div>
                </article>
              ))}
            </div>
     
          </section>

   

          <section className="tags mb-4">
            <h2 className="sidebar-title">Trending</h2>
            <ul className="tags-list list-unstyled d-flex flex-wrap gap-2">
              {tags.map((tag, index) => (
                <li className="tag-item me-2" key={index}>
                  <a className="tag-link" href="#0">
                    {tag}
                  </a>
                </li>
              ))}
            </ul>
          </section>

          <section className="categories">
            <h2 className="sidebar-title">Categories</h2>
            <ul className="categories-list list-unstyled">
              {categories.map((category, index) => (
                <li
                  className="category-item border-bottom pb-2 mb-2"
                  key={index}
                >
                  <a className="category-link" href="#0">
                    {category.name}{" "}
                    <span className="category-count">({category.count})</span>
                  </a>
                </li>
              ))}
            </ul>
          </section>
        </aside>
      </main>
    </div>
  </>
);

export default Blog;
